//styles
import styled from 'styled-components';
import { between, rem } from 'polished';
import vars from './varss';
//framer-motion
import { motion } from 'framer-motion';

//styled
const Heading = styled(motion.h1)`
  color: ${vars.colors.text};
  font-size: ${props => props.fontSize ? rem(props.fontSize + 'px') : rem('66px')};
  font-weight: ${props => props.fontWeight ? props.fontWeight : 'normal'};
  line-height: ${props => props.lineHeight ? props.lineHeight : 1.25};
  margin-bottom: ${props => props.marginBottom ? rem(props.marginBottom + 'px') : rem('20px')};
  @media (max-width: ${vars.media.lgMax}) {
    font-size: ${props => props.size ? between((props.size - 10)+'px', props.size+'px') : between('26px', '66px')};
  }
`;

export default Heading;